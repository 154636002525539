.rdp-day_today:not(.rdp-day_outside) {
    color: red;
}

.rdp-nav_button-updated {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    padding: 0.25em;
    border-radius: 100% !important;
    box-shadow: none;
}

.button-leftright {
    box-shadow: none !important;
}

.rdp-nav_button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--rdp-cell-size);
    height: var(--rdp-cell-size);
    padding: 0.25em;
    border-radius: 100%;
    box-shadow: none !important;
}

.rdp-caption_label {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0 0.25em;
    white-space: nowrap;
    color: currentColor;
    border: 0;
    border: 2px solid transparent;
    font-family: inherit;
    font-size: 120%;
    /* font-weight: bold; */
}

.rdp {
    --rdp-cell-size: 36px;
    --rdp-accent-color: #0000ff;
    --rdp-background-color: #e7edff;
    --rdp-accent-color-dark: #3003e1;
    --rdp-background-color-dark: #180270;
    --rdp-outline: 2px solid var(--rdp-accent-color);
    /* Outline border for focused elements */
    --rdp-outline-selected: 3px solid var(--rdp-accent-color);
    /* Outline border for focused _and_ selected elements */

    margin: 0em;
}

/* Use a media query for screens narrower than 400px */
@media (max-width: 323px) {
    .rdp {
        --rdp-cell-size: 33px !important; /* Adjusted size for screens narrower than 400px */
    }
}
