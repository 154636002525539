body {
    /* font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif; */
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: grayscale;
    color: #4e4e4e;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

div {
    background-color: transparent;
}
