.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.form-profile {
    box-shadow: none;
    padding: 0;
    width: auto;
    min-width: 0;
    display: flex;
    flex-direction: row;
}

.App-link {
    color: #61dafb;
}

.fa-icon-styles {
    --fa-border-padding: 2em;
    --fa-border-radius: 0.25em;
    --fa-pull-margin: 2em;
    font-size: 2em;
}

.fa-icon-chevron-styles {
    --fa-border-padding: 2em;
    --fa-border-radius: 0.25em;
    --fa-pull-margin: 2em;
    font-size: 1.8em;
}

.fa-icon-available-on {
    font-size: 1em;
    color: #53d765;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.slide-in {
    /* position: fixed; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    /* background: #0f0f0f; */
    /* transform-origin: top; */
}
.slide-out {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #0f0f0f;
    transform-origin: top;
}
